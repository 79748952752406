import React, { useState, useEffect, useRef } from 'react';
import '../css/MovingDots.css';

const MovingDots = ({prevCursorX, prevCursorY}) => {
  const [dots, setDots] = useState([]);
  const canvasRef = useRef(null);

  //dot + line control 
  const dotOffset = 151; //border offset for dot location (allows travel offscreen) (lineLength + 1)
  //const numberOfDots = 200; //number of total dots - fixed number 
  const numberOfDots = Math.floor(window.innerWidth / 10) + 40; //variable based on screen width
  const cursorPushDistance = 100; //distance from cursor for dot to be pushed
  const lineLength = 150; //max length of lines (connecting 2 dots)

  //initialize dots
  useEffect(() => {
    console.log(numberOfDots);
    const initialDots = Array.from({ length: numberOfDots }, (_, index) => ({
      id: index,
      y: ((Math.random() * window.innerHeight) - dotOffset) / (1/dotOffset),
      x: ((Math.random() * window.innerWidth) - dotOffset) / (1/dotOffset),
      speed: (Math.random() * 0.3) + 0.2, //0.2 min speed
    }));
    setDots(initialDots);
    //use updateDots, interval 16 (can change)
    const intervalId = setInterval(updateDots, 16);
    return () => clearInterval(intervalId);
  }, [numberOfDots]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, window.innerWidth, window.innerHeight);

    dots.forEach((dot1, index1) => {
      const distanceToCursor = Math.sqrt((prevCursorX - dot1.x) ** 2 + (prevCursorY - dot1.y) ** 2);
      const speedMultiplier = 5; //Set to 5 for really slow avoidance speed
  
      if (distanceToCursor < cursorPushDistance) {
        // If the dot is too close to the cursor, move it away in opposite direction
        const angle = Math.atan2(dot1.y - prevCursorY, dot1.x - prevCursorX);
        const newY = dot1.y + Math.sin(angle) * speedMultiplier;
        const newX = dot1.x + Math.cos(angle) * speedMultiplier;
    
        if (!isNaN(newY) && !isNaN(newX)) {
          dot1.y = newY;
          dot1.x = newX;
        } //if
      } //if

     //draw the lines
      dots.forEach((dot2, index2) => {
        if (index1 !== index2) {
          const distance = Math.sqrt((dot2.x - dot1.x) ** 2 + (dot2.y - dot1.y) ** 2);
          if (distance < lineLength) {
            const xOffset = 6;
            const yOffset = 5;
            context.beginPath();
            context.moveTo(dot1.x + xOffset, dot1.y + yOffset);
            context.lineTo(dot2.x + xOffset, dot2.y + yOffset);
            context.strokeStyle = '#39344b';
            context.lineWidth = 2;
            context.stroke();
          }
        }
      });
    });
  }, [dots, prevCursorX, prevCursorY]);

  const updateDots = (cursorX, cursorY) => {
    setDots((prevDots) =>
      prevDots.map((dot) => {
        const distanceToCursor = Math.sqrt((prevCursorX - dot.x) ** 2 + (prevCursorY - dot.y) ** 2);
        const speedMultiplier = 10; //avoidance speed
  
        if (distanceToCursor < cursorPushDistance) {
          // If the dot is too close to the cursor, move it away
          const angle = Math.atan2(dot.y - cursorY, dot.x - cursorX);
          const newY = dot.y + Math.sin(angle) * speedMultiplier;
          const newX = dot.x + Math.cos(angle) * speedMultiplier;
  
          if (!isNaN(newY) && !isNaN(newX)) {
            return { ...dot, y: newY, x: newX };
          } //if
        } //if
        // Move the dot in its original direction (can adjust for other movement)
        const adjustedSpeed = dot.speed;
        //offset works for all directions
        const newY = ((dot.y + adjustedSpeed + (1 * dotOffset)) % (window.innerHeight + (2 * dotOffset))) - (1 * dotOffset);
        const newX = ((dot.x + adjustedSpeed + (1 * dotOffset)) % (window.innerWidth + (2 * dotOffset))) - (1 * dotOffset);
        
        if (!isNaN(newY) && !isNaN(newX)) {
          return { ...dot, y: newY, x: newX };
        } //if        
        return dot; //If the calculations result in NaN, return the original dot
      })
    );
  };
  
  return (
    <div className="white-edge-eliminator">
      <div className="moving-dots-container">
        {dots.map((dot) => (
          <div key={dot.id} className="dot" style={{ top: dot.y, left: dot.x }} />
        ))}
        <canvas ref={canvasRef} className="line-canvas" width={window.innerWidth} height={window.innerHeight} />
      </div>
    </div>
  );
};

export default MovingDots;