import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MovingDots from './components/MovingDots.js';
import Navbar from './components/Navbar.js';

import HomePage from "./pages/HomePage.js";
import AboutPage from "./pages/AboutPage.js";
import ExperiencePage from "./pages/ExperiencePage.js";

function App() {

  const [prevCursorX, setPrevCursorX] = useState(0);
  const [prevCursorY, setPrevCursorY] = useState(0);

  const mouseMoveHandler = (event) => {
    const localX = event.clientX;
    const localY = event.clientY;
    setPrevCursorX(localX);
    setPrevCursorY(localY);
  };

  return (
    <div className="App">
      <div onMouseMove={mouseMoveHandler}>
      <BrowserRouter>
        <Navbar />
        <MovingDots prevCursorX={prevCursorX} prevCursorY={prevCursorY} />
        
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
        </Routes>
        
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
