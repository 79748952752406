import React, { useRef } from "react";
import "./styles.css";
import { motion, useViewportScroll, useTransform } from "framer-motion";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [0, -distance]);
}

function Card({ title, description, image, cardRef }) {
  return (
    <div className="card" ref={cardRef}>
      <img src={image} alt={title} />
      <div className="text-container">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default function ScrollableCards() {
  const cards = [
    { id: 1, title: "Experience One", description: "Description 1", image: "/1.jpg", year: 2024 },
    { id: 2, title: "Experience Two", description: "Description 2", image: "/2.jpg", year: 2024 },
    { id: 3, title: "Experience Three", description: "Description 3", image: "/3.jpg", year: 2023 },
    { id: 4, title: "Experience Four", description: "Description 4", image: "/4.jpg", year: 2023 },
    { id: 5, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 },
    { id: 6, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 },
    { id: 7, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 },
    { id: 8, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 },
    { id: 9, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 },
    { id: 10, title: "Experience Five", description: "Description 5", image: "/5.jpg", year: 2022 }
  ];

  const refs = useRef(cards.map(() => React.createRef()));

  const { scrollY } = useViewportScroll();
  const ySlow = useParallax(scrollY, 200); // Slower parallax effect for left section

  const handleClick = (index) => {
    refs.current[index].current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container">
      <div className="left-section">
        <motion.div className="left-content" style={{ y: ySlow }}>
          {cards.map((card, index) => (
            <div key={card.id} className="left-item" onClick={() => handleClick(index)}>
              <div className="year">{card.year}</div>
              <div className="title">{card.title}</div>
            </div>
          ))}
        </motion.div>
      </div>
      <div className="right-section">
        {cards.map((card, index) => (
          <div className="card-wrapper" key={card.id} ref={refs.current[index]}>
            <Card {...card} cardRef={refs.current[index]} />
          </div>
        ))}
      </div>
    </div>
  );
}
