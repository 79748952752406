import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

const Navbar = () => {
    const pages = ["Home", "About" /*, "Projects", "Experience", "Fun"*/]; //add back in when ready
    const [activeTab, setActiveTab] = useState(pages[0]); // Set the initial active tab

    const handleTabClick = (page) => {
        setActiveTab(page);
    };

    return (
        <header className="navbar-header">
            <nav className="navbar">
                <ul className="navbar-tabs">
                    {pages.map(page => {
                        const path = page === "Home" ? "/" : `/${page.toLowerCase()}`;
                        return (
                            <li key={page} className="list-item">
                                <Link to={path}>
                                    <TabItem label={page} active={activeTab === page} onClick={() => handleTabClick(page)}></TabItem>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </header>
    );
};

const TabItem = ({ label, active, onClick }) => {
    return (
        <div className={`tab-item ${active ? 'active' : ''}`} onClick={onClick}>
            <div className="text">{label}</div>
            <div className="pill"></div>
        </div>
    );
};

export default Navbar;
