import React from 'react';
import ScrollableCards from './ScrollableCards';

const ExperiencePage = () => {
  return (
    <div className="experience-page">
      <ScrollableCards />
    </div>
  );
};

export default ExperiencePage;
