import React from 'react';
import '../css/AboutPage.css'; 
import headshot from "../images/headshot-cropped.png"

const AboutPage = () => {
  return (
    <div className="about-container">
      <div className="about-text">
        <h1>About Me</h1>
        <p>I'm curently studying Computer Science at the University of Georgia, with a keen interest in Software Engineering. I have experience in both front and back end design. </p>
        <p>My goal is to work on projects that have a positive impact on the world, and to continue learning and growing as a developer.</p>
        <p>In my free time, I enjoy backpacking, playing games, <a href="https://www.goodreads.com/user/show/109942377-codey" target="_blank" rel="noreferrer">reading books</a>, and watching movies.</p>
      </div>
      <div className="about-image">
        <img src={headshot} alt="Codey Borrelli Head Shot" />
      </div>
    </div>
  );
};

export default AboutPage;
